<template>
  <div class="flex align-center bg-white header" style="height: 100px;line-height: 80px;">
    <div style="margin: 0 auto;width: calc(100% - 200px);" class="flex justify-between align-center ">
      <div style="" class="flex align-center">
        <img alt="avatar" :style="{ width: '80px', height: '80px' }" src="@/assets/logo.png"/>
        <div style="font-size: 50px;padding-left: 20px;" class="text-bold text-black">
          {{ $t('message.LogoTitle') }}
        </div>
      </div>
      <div style="width: 234px;" class="flex align-center">
        <el-input size="small" style="width: 204px"></el-input>
        <el-button size="small" type="success" icon="Search"></el-button>
      </div>
    </div>
  </div>
  <!-- nav导航 -->
  <div class="nav flex" style="height: 70px;line-height: 70px;background-color: #3e9d49;">
    <div style="margin: 0 auto;width: calc(100% - 200px);" class="flex justify-between align-center">
      <div class="left">
        <nav class="header_nav" style="user-select:none;">
          <div class="header_textNav">
            <ul style="margin: 0;padding-inline-start: 0px;" class="flex align-center  ">
              <li
                  v-for="(item, index) in header_nav"
                  :key="index"
                  style="cursor:pointer;list-style: none;"
                  :class="item.value == header_tabcur ? 'header_textChecked header_textPublic ' : 'header_textPublic'"
                  @click="headerSelect"
                  :data-id="item.value"
              >
                {{ $t(item.label) }}
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="right" style="width: 234px;">
        <div class="flex align-center justify-center text-white">
          <div class="lug" @click="lang('zh-CN')">{{ $t('message.Chinese') }}</div>
          <div class="" style="padding: 0 5px;">/</div>
          <div class="lug" @click="lang('en-US')">English</div>
          <!--					<a-button type="text" style="color: #ffffff;" @click="lang('zh-CN')">{{ $t('message.Chinese') }}</a-button>-->
          <!--					<div class="" @click="lang('en-US')">/</div>-->
          <!--					<a-button type="text" style="color: #ffffff;" @click="lang('en-US')">English</a-button>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, ref, defineExpose} from 'vue';
import {useRouter} from 'vue-router';

const props = defineProps(['navType']);
const router = useRouter();
const {ctx} = getCurrentInstance();
const header_nav = ref([
  {value: 1, label: 'message.home'},
  {value: 2, label: 'message.OurServices'},
  {value: 3, label: 'message.ContactUs'},
  {value: 4, label: 'message.New'},
  {value: 5, label: 'message.CargoTracking'}
]);
const header_tabcur = ref(1);
const fromFather = defineEmits(['toSonEvent']);
const headerSelect = e => {
  if (e.currentTarget != undefined) {
    header_tabcur.value = e.currentTarget.dataset.id;
  } else {
    header_tabcur.value = e;
  }
  fromFather('toSonEvent', header_tabcur.value);
  // router.push({ name: 'home' });
};
const placeholder = ref('');
const lang = type => {
  if (type === 'zh-CN') {
    ctx.$i18n.locale = 'zh-CN';
    placeholder.value = '搜索';
    localStorage.setItem('lang', 'zh-CN');
  } else {
    ctx.$i18n.locale = 'en-US';
    placeholder.value = 'Search';
    localStorage.setItem('lang', 'en-US');
  }
};

defineExpose({headerSelect});
</script>
<style scoped lang="scss">
// .header {
// 	position: fixed;
// 	z-index: 999;
// 	top: 0;
// 	height: 66px;
// 	width: 100%;
// 	background: rgba(34, 83, 171, 0.3);
// 	box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
// }

/* 头部导航 */
.header_textPublic {
  text-align: center;
  font-size: 18px;
  width: 128px;
  color: #fff;
  font-weight: normal;
}

.header_textChecked {
  color: #3e9d49;
  width: 128px;
  background-color: white;
  font-size: 18px;
  font-weight: bold;
}

.lug {
  cursor: pointer;
}

.lug:hover {
  color: yellow;
}
</style>
