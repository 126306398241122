import http from "@/utlis/http";

export default {
  news: {
    list: (data) => {
      return http({
        url: "/api/v1/news/list",
        data,
        op: false,
      }).then((res) => res);
    },
    add: (data) => {
      return http({
        url: "/api/v1/news/add",
        data,
      }).then((res) => res);
    },
    edit: (data) => {
      return http({
        url: "/api/v1/news/edit",
        data,
      }).then((res) => res);
    },
    del: (data) => {
      return http({
        url: "/api/v1/news/delete",
        data,
      }).then((res) => res);
    },
  },
  getList: (data) => {
    return http({
      url: "/api/v1/banner/getList",
      data,
      op: false,
    }).then((res) => res);
  },
  projectList: (data) => {
    return http({
      url: "/api/v1/project/getList",
      data,
      op: false,
    }).then((res) => res);
  },
  syslist: (data) => {
    return http({
      url: "/api/v1/sys/list",
      data,
      op: false,
    }).then((res) => res);
  },
};
