import http from '@/utlis/http'

export default {
	sms_send: data => {
		return http({
			url: '/api/v1.sms/send',
			data
		}).then(res => res);
	},
	area: data => {
		return http({
			url: '/api/v1.area/list',
			data,
			op: false
		}).then(res => res);
	},
	districts: data => {
		return http({
			url: '/api/v1.area/districts',
			data,
			op: false
		}).then(res => res);
	},
	city: data => {
		return http({
			url: '/api/v1.area/citys',
			data,
			op: false
		}).then(res => res);
	},
	citys: data => {
		return http({
			url: '/api/v1.area/lists',
			data,
			op: false
		}).then(res => res);
	},



	menuListWithLevel: data => {
		return http({
			url: '/api/v1.roles/menuListWithLevel',
			data,
			op: false
		}).then(res => res);
	},
	rolesList: data => {
		return http({
			url: '/api/v1.roles/list',
			data,
			op: false
		}).then(res => res);
	},
	area: data => {
		return http({
			url: '/api/v1.area/lists',
			data,
			op: false
		}).then(res => res);
	},
	roles: {
		add: data => {
			return http({
				url: '/api/v1.roles/add',
				data,
			}).then(res => res);
		},
		edit: data => {
			return http({
				url: '/api/v1.roles/edit',
				data,
			}).then(res => res);
		},
		del: data => {
			return http({
				url: '/api/v1.roles/remove',
				data,
			}).then(res => res);
		},
		update: data => {
			return http({
				url: '/api/v1.roles/update',
				data,
			}).then(res => res);
		},
	},
	menu: {
		add: data => {
			return http({
				url: '/api/v1.menu/add',
				data
			}).then(res => res);
		},
		edit: data => {
			return http({
				url: '/api/v1.menu/edit',
				data
			}).then(res => res);
		},

		list: data => {
			return http({
				url: '/api/v1.menu/list',
				data,
				op: false
			}).then(res => res);
		},
		topOne: () => {
			return http({
				url: '/api/v1.menu/top',
				op: false
			}).then(res => res);
		}
	},
	dept: {
		add: data => {
			return http({
				url: '/api/v1.dept/add',
				data
			}).then(res => res);
		},
		edit: data => {
			return http({
				url: '/api/v1.dept/edit',
				data
			}).then(res => res);
		},

		list: data => {
			return http({
				url: '/api/v1.dept/list',
				data,
				op: false
			}).then(res => res);
		},
		del: data => {
			return http({
				url: '/api/v1.dept/delete',
				data,
			}).then(res => res);
		},
		all: data => {
			return http({
				url: '/api/v1.dept/all',
				data,
				op: false
			}).then(res => res);
		},
		top: () => {
			return http({
				url: '/api/v1.dept/top',
				op: false
			}).then(res => res);
		}
	},
	user: {
		add: data => {
			return http({
				url: '/api/v1.user/add',
				data
			}).then(res => res);
		},
		edit: data => {
			return http({
				url: '/api/v1.user/edit',
				data
			}).then(res => res);
		},

		list: data => {
			return http({
				url: '/api/v1.user/list',
				data,
				op: false
			}).then(res => res);
		},
		del: data => {
			return http({
				url: '/api/v1.user/delete',
				data,
			}).then(res => res);
		},


	}



}
