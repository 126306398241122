import {createApp} from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//默认样式
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ArcoVue from '@arco-design/web-vue';
import i18n from "@/language";
// 额外引入图标库
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import '@arco-design/web-vue/dist/arco.css';



//rem适配文件
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(store);
app.use(router)
app.use(ElementPlus);
app.use(ArcoVue)
app.use(ArcoVueIcon);
app.use(i18n);
app.mount('#app')
