<template>
  <!-- 轮播图 -->
  <div class="bg-white">
    <el-carousel height="520px" :interval="5000" arrow="always">
      <el-carousel-item v-for="(item,index) in bannerList" :key="item">
        <el-image :src="item.url" :fit="'cover'" style="height: 100%;width: 100%"></el-image>
      </el-carousel-item>
    </el-carousel>
    <!-- 新闻板块 -->
    <div class="new_box">
      <div style="margin-top: 43px;" class="text-center">
        <div class="title">{{ $t('message.NewsCenter') }}</div>
        <div style="margin-top: 6px;">News Center</div>
      </div>
      <div class="flex align-center" style="margin-top: 46px;">
        <div style="width: 40%;">
          <a-card hoverable @click="Tackdetails(firstNew)">
            <template #cover>
              <div :style="{ height: '392px' }"><img v-if="firstNew != null" :style="{ width: '100%', height: '100%' }" alt="" :src="firstNew.image" /></div>
            </template>
            <a-card-meta>
              <template #description>
                <a-typography-paragraph
                    :ellipsis="{
										rows: 2
									}"
                    v-if="firstNew != null"
                >
                  {{ firstNew.content.replace(/<[^>]*>/g, '') }}
                </a-typography-paragraph>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div style="width: 60%;">
          <a-list :bordered="false" :split="false">
            <a-list-item v-for="(item, index) in NewsItems" :key="item.id" :style="{ padding: '5px 20px', lineHeight: 'normal' }" @click="Tackdetails(item)">
              <a-list-item-meta :title="item.title" :style="{ padding: '0px'}">
                <template #description>
                  <a-typography-paragraph
                      :ellipsis="{
											rows: 6
										}"
                  >
                    {{ item.content.replace(/<[^>]*>/g, '') }}
                  </a-typography-paragraph>
                </template>
                <template #avatar>
                  <a-avatar shape="square" :style="{ width: '208px', height: '140px' }"><img alt="avatar" :src="item.image" /></a-avatar>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
<!--    <div class="new_box">-->
<!--      <div style="margin-top: 60px;" class="text-center">-->
<!--        <div class="title">{{ $t('message.NewsCenter') }}</div>-->
<!--        <div style="margin-top: 6px;">News Center</div>-->
<!--      </div>-->
<!--      <el-row :gutter="20" style="margin-top: 60px">-->
<!--        <template v-for="(item,index) in NewsItems" :key="index">-->
<!--          <el-col :span="6" v-if="index < 4">-->
<!--            <div class="news-item" @click.stop="Tackdetails(item)">-->
<!--              <div class="solid"  style="height: 300px">-->
<!--                <el-image fit="cover" style="width: 100%;height: 100%" :src="item.image"></el-image>-->
<!--              </div>-->
<!--              <div class="solid padding-10 text-cut bg-set-green label">-->
<!--                {{item.title}}-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </template>-->
<!--      </el-row>-->
<!--    </div>-->
    <!-- 服务项目 -->
    <div style="background-color: #FAFAFA;">
      <div class="box">
        <div style="padding-top: 33px;" class="text-center">
          <div class="title">{{ $t('message.ServiceItems') }}</div>
          <div style="margin-top: 6px;">Service Items</div>
        </div>
        <div style="margin-top: 46px;">
          <a-row :gutter="20">
            <a-col :span="8" v-for="(item, index) in projectList" :key="item.id" @mouseover="serviceType" :data-id="item.id">
              <div :style="{ marginTop: '20px' }">
                <el-image fit="cover" style="width: 100%;height: 340px" :src="item.url"></el-image>
<!--                <img fit="cover" alt="avatar" :style="{ width: '100%', height: '342px' }" :src="item.url" />-->
                <div style="line-height: 66px;height: 66px;" class="text-center" :class="item.id == serviceId ? 'service_active ' : 'service_color'">
                  <span v-if="ctx.$i18n.locale === 'zh-CN'">{{ item.title_cn }}</span>
                  <span v-if="ctx.$i18n.locale === 'en-US'">{{ item.title_en }}</span>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 400px">
<!--      <el-image fit="cover" style="width: 100%;height: 470px" src="@/assets/svg/sucai17.png"></el-image>-->
      <img fit="cover" alt="avatar" :style="{ width: '100%', height: '100%' }" src="@/assets/svg/sucai17.png" />
    </div>
<!--    <div style="background-color: #FAFAFA;margin-top: 30px">-->
<!--      <div class="box">-->
<!--        <div style="padding-top: 33px;" class="text-center">-->
<!--          <div class="title">{{ $t('message.ServiceItems') }}</div>-->
<!--          <div style="margin-top: 6px;">Service Items</div>-->
<!--        </div>-->
<!--        <div style="margin-top: 40px;">-->
<!--          <el-tabs v-model="activeName" class="demo-tabs" @tab-change="handleClick">-->
<!--            <el-tab-pane v-for="(item,index) in projectList" :key="index" :label="ctx.$i18n.locale === 'zh-CN' ?item.title_cn:item.title_en" :name="index"></el-tab-pane>-->
<!--          </el-tabs>-->
<!--          <el-carousel class="margin-top-20" ref="CarouselProject" :interval="4000" type="card" height="280px" @change="carChange" :initial-index="activeName">-->
<!--            <el-carousel-item v-for="(item,index) in projectList" :key="index">-->
<!--              <el-image :src="item.url" :fit="'cover'" style="height: 100%;width: 100%"></el-image>-->
<!--            </el-carousel-item>-->
<!--          </el-carousel>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!-- 运输方式-->
    <div class="box">
      <div style="margin-top: 72px;" class="text-center">
        <div class="title">{{ $t('message.PopularTransportation') }}</div>
        <div style="margin-top: 6px;">Popular transportation</div>
      </div>
      <div style="margin-top: 42px;">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="" :style="{ width: '100%', height: '388px', position: 'relative' }" style="border-radius: 5px">
              <img alt="avatar" style="border-radius: 5px" :style="{ width: '100%', height: '100%' }"
                   src="@/assets/svg/sucai5.png"/>
              <div class="text-center text-bold box-title">
                <span style="">{{ $t('message.SeaTransportation') }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="" style="border-radius: 5px" :style="{ width: '100%', height: '388px', position: 'relative' }">
              <img alt="avatar" style="border-radius: 5px" :style="{ width: '100%', height: '100%' }"
                   src="@/assets/svg/sucai6.png"/>
              <div class="text-center text-bold box-title">
                <span style="">{{ $t('message.RoadTransportation') }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, getCurrentInstance} from 'vue';
import api from '@/api';
import {baseUrl} from "@/utlis/config";

const {ctx} = getCurrentInstance();
const props = defineProps(['header', 'home']);

const activeName = ref(0);
const CarouselProject = ref(null)

const handleClick = val => {
  CarouselProject.value.activeIndex = val
  CarouselProject.value.setItem(val)
}

const carChange = val => {
  activeName.value = val;
}

const firstNew = ref(null);
const NewsItems = ref([]);
const GetNews = () => {
  api.news.list({
    text: '',
    page: 1,
    row: 5
  }).then(res => {
    let arr = res.data.data;
    firstNew.value = arr[0];
    // NewsItems.value = arr.slice(1);
    NewsItems.value = res.data.data;
  });
};
const bannerList = ref([]);
const Getbaner = () => {
  api.news.getList({
    type: 1,
    page: 1,
    row: 999
  }).then(res => {
    bannerList.value = res.data;
  });
};
const projectList = ref([]);
const Getproject = () => {
  api.news.projectList({
    type: 1,
    page: 1,
    row: 999
  })
      .then(res => {
        projectList.value = res.data;
      });
};
const Details = ref(false);
const newDeail = ref(null);

const Tackdetails = data => {
  newDeail.value = data;
  Details.value = true;
  props.header.headerSelect(4);
};

const serviceId = ref(1);

const serviceType = val => {
  serviceId.value = val.currentTarget.dataset.id;
};
defineExpose({Details, newDeail});

onMounted(() => {
  GetNews();
  Getbaner();
  Getproject();
});
</script>

<style lang="scss">
// 服务信息
.service_color {
  background-color: #fff;
  color: #000000;
}

.service_active {
  background-color: #3e9d49;
  color: #fff;
}

.title {
  font-size: 30px;
  font-weight: bold;
}

.new_box {
  width: calc(100% - 200px);
  margin: 0 auto;
  padding-bottom: 40px;
}

.box {
  width: calc(100% - 200px);
  margin: 0 auto;
  padding-bottom: 70px;
}

.box-title {
  border-radius: 0 0 5px 5px;
  width: 100%;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 63px;
  height: 63px;
  background-color: #000000;
  opacity: 0.8;
  position: absolute;
  bottom: 0;
}

.news-item{
  cursor: pointer;
}

.news-item:hover .label{
  background-color: #1cbbb4;
}
</style>
