<template>
	<div class="bg-white">
		<div style="height: 230px;background-color: #353436;">
			<div style="margin: 0 auto;width:calc(100% - 200px);">
        <el-row :gutter="20">
          <el-col :span="18">
            <el-row :gutter="20">
              <template v-for="(item,index) in contactList" :key="index">
                <el-col class="margin-top-20 text-gray" :span="12" v-if="ctx.$i18n.locale === 'zh-CN' && index % 2 === 0">
                  <div class="text-bold">
                    {{item.country_name}}
                  </div>
                  <div class="margin-top-5">
                    {{ $t('message.contacts')+': '+item.contacts_name}}
                  </div>
                  <div class="margin-top-3">
                    {{ $t('message.contactInformation')+': '+ item.contact}}
                  </div>
                  <div class="margin-top-3">
                    {{ $t('message.warehouseAddress')+': '+ item.address}}
                  </div>
                </el-col>
                <el-col class="margin-top-20 text-gray" :span="12" v-if="ctx.$i18n.locale !== 'zh-CN' && index % 2 !== 0">
                  <div class="text-bold">
                    {{item.country_name}}
                  </div>
                  <div class="margin-top-5">
                    {{ $t('message.contacts')+': '+item.contacts_name}}
                  </div>
                  <div class="margin-top-3">
                    {{ $t('message.contactInformation')+': '+ item.contact}}
                  </div>
                  <div class="margin-top-3">
                    {{ $t('message.warehouseAddress')+': '+ item.address}}
                  </div>
                </el-col>
              </template>
            </el-row>
          </el-col>
          <el-col :span="6">
            <div class="margin-top-20 text-gray">2021 {{ $t('message.Copyright') }}</div>
          </el-col>
        </el-row>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue';
import api from '@/api';
const { ctx } = getCurrentInstance();
const contactList = ref([]);

const Getcontact = () => {
	api.news.syslist().then(res => {
		contactList.value = res.data;
	});
};
onMounted(() => {
	Getcontact();
});
</script>

<style></style>
