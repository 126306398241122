<template>
	<!-- 轮播图 -->
  <el-carousel height="520px" :interval="5000" arrow="always">
    <el-carousel-item v-for="(item,index) in bannerList" :key="item">
      <el-image :src="item.url" :fit="'cover'" style="height: 100%;width: 100%"></el-image>
    </el-carousel-item>
  </el-carousel>

	<!-- 服务项目 -->
	<div style="background-color: #FAFAFA;">
		<div class="box">
			<div style="padding-top: 33px;" class="text-center">
				<div class="title">{{ $t('message.ServiceItems') }}</div>
				<div style="margin-top: 6px;">Service Items</div>
			</div>
			<div style="margin-top: 46px;">
        <el-row :gutter="20">
          <el-col :span="12" v-for="(item, index) in projectList" :key="item.id" :data-id="item.id">
            <div style="margin-top: 20px" class="item-box">
              <div style="width: 100%;height: 342px">
                <img :src="item.url" style="width: 100%;height: 100%" alt="">
              </div>
              <div style="line-height: 66px;height: 66px;margin-top: 0" class="text-center label">
                <span v-if="ctx.$i18n.locale === 'zh-CN'">{{ item.title_cn }}</span>
                <span v-if="ctx.$i18n.locale === 'en-US'">{{ item.title_en }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
<!--				<a-row :gutter="20">-->
<!--					<a-col :span="8" v-for="(item, index) in projectList" :key="item.id" @mouseover="serviceType" :data-id="item.id">-->
<!--						-->
<!--					</a-col>-->
<!--				</a-row>-->
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue';
import api from '@/api';
import {baseUrl} from "@/utlis/config";
const props = defineProps(['navType']);
const { ctx } = getCurrentInstance();
const serviceId = ref(1);
const bannerList = ref([]);
const Getbaner = () => {
	api.news.getList({
			type: 2,
			page: 1,
			row: 999
		}).then(res => {
			bannerList.value = res.data;
		});
};
const projectList = ref([]);
const Getproject = () => {
	api.news.projectList({
			page: 1,
			row: 999
		})
		.then(res => {
			projectList.value = res.data;
		});
};

const serviceType = val => {
	serviceId.value = val.currentTarget.dataset.id;
};
onMounted(() => {
	Getbaner();
	Getproject();
});
</script>

<style lang="scss">
// 服务信息
.service_color {
	background-color: #fff;
	color: #000000;
}
.service_active {
	background-color: #f18700;
	color: #fff;
}
.title {
	font-size: 30px;
	font-weight: bold;
}

.box {
	width: calc(100% - 200px);
	margin: 0 auto;
	padding-bottom: 70px;
}

.item-box{
  cursor: pointer;
}

.item-box .label{
  opacity: 0.8;
  background-color: #3e9d49;
  color: #FFFFFF;
}

.item-box:hover .label{
  background-color: #1cbbb4;
}
</style>
