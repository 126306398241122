import http from "@/utlis/http";
import axios from "axios";

const req = require.context("./", true, /\.js$/);

const api = {
    news:{
        list: (data) => {
            return http({url: "/api/v1.news/list", data, op: false,}).then((res) => res);
        },
        getList: (data) => {
            return http({url: "/api/v1.banner/getList", data, op: false,}).then((res) => res);
        },
        projectList: (data) => {
            return http({url: "/api/v1.project/getList", data, op: false,}).then((res) => res);
        },
        syslist: (data) => {
            return http({url: "/api/v1.sys/list", data, op: false}).then((res) => res);
        },
    },
    order:{
        search: data => {
            return new Promise((resolve,reject) => {
                axios.post('https://api.dsc56.com/api/admin/v1/order/searchOrder',data).then(res => {
                    resolve(res.data)
                })
            })
        }
    }
};

/**
 * 接口主文件
 * 接口文件请按文件区分，使用 api.文件名.方法如 api.base.login()
 */

// req.keys().forEach((item) => {
//     if (item !== "./index.js") {
//         let keys = item.split("/").pop().replace(/\.js$/, "");
//         let moduleFile = req(item);
//         let value = moduleFile.default || moduleFile;
//         api[keys] = value;
//     }
// });

export default api;