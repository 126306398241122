import { createStore } from "vuex";

const dynamicModules = {};

const files = require.context("./", true, /\.js$/);

files.keys().forEach((item) => {
  if (item !== "./index.js") {
    let moduleName = item.split("/").pop().replace(/\.js$/, "");
    let moduleFile = files(item);
    let value = moduleFile.default || moduleFile;
    dynamicModules[moduleName] = value;
  }
});

export default createStore({
  modules: dynamicModules,
});
