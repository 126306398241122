export default {
	message: {
		Chinese: 'Chinese',
		home: 'Home',
		AboutUs: 'About',
		OurServices: 'Services',
		ContactUs: 'Contact',
		CargoTracking: 'Tracking',
		NigeriaT: 'Nigeria contact information',
		Domestic: 'Domestic contact information',
		Address: 'Address',
		Phone: 'Phone',
		EMail: 'E-Mail',
		Copyright: 'All rights reserved Dremick Supply Chain',
		MobilePhoneVersion: 'MobilePhoneVersion',
		NewsCenter: 'News Center',
		ServiceItems: 'Service Items',
		PopularTransportation: 'Popular Transportation',
		SeaTransportation: 'Sea Transportation',
		RoadTransportation: 'Road Transportation',
		NigeriaClearance: 'Nigeria import clearance',
		ShippingLCL: 'Shipping and LCL',
		WestAfricantransport: 'West African land transport',
		Nigerianexport: 'Nigerian Export',
		warehouseService: 'Warehouse Service',
		Localdistribution: 'Local Distribution',
		CompanyProfile: 'Company Profile',
		CompanyName: 'Dremik Supply Chain',
		CompanyTitle: "Since its establishment, dremik supply chain Co., Ltd. has been based in Nigeria and focused on sea and air transportation logistics between China and Nigeria. Our advantages lie in Nigeria's maritime import customs clearance, export fast entry, marine bulk cargo LCL, as well as China Nigeria air customs clearance and local warehousing and distribution.",
		workConcept: 'Work Concept',
		worktitle: 'Heart service interpretation of my true feelings, sincere dedication to win your satisfaction',
		enterpriseCulture: 'Enterprise Culture',
		enterpriseTitle: 'The achievement of customers is the achievement of our own',
		serviceConcept: 'Service Concept',
		serviceTitle: 'Focus on China-Nigeria logistics, reduce logistics costs between China and Nigeria, improve logistics efficiency Enterprise mission to provide a platform for employees, for customers to achieve Nigeria market, reduce logistics costs between China and Nigeria for customers, improve logistics efficiency so as to better participate in the market competition, we hope to be your best partner and assistant in this process.',
		content1: 'Delmi has a bonded warehouse approved by the Federal Government of Nepal in Lagos. Imported goods including containers and bulk cargo are transferred to the bonded warehouse for customs clearance. Complete customs clearance process quickly and efficiently. And Delemick Nigeria operates 40 trailer trucks, which are free from high rates when ports are crowded.',
		content2: 'Delmi provides professional Marine services and LCL solutions, the best quality one-stop service. No matter where your goods are in China, no matter the quantity of goods, our professional team will be able to customize the best shipping or LCL solution with low cost and high efficiency according to your needs.',
		content3: 'Delemico has proven experience in land transport across West Africa, from Nigeria to Niger, Benin, Togo, Ghana, Burkina Faso, Cameroon and other countries.',
		content4: "In Lagos, Delemick rents a shipping company's empty container yard so that customers' export goods can be loaded directly into empty containers, reducing the pain of transferring empty containers. With 5,000 square meters of yard in Port Harcourt, customers can ship any quantity of export goods to this yard at any time.",
		content5: 'Delmik owns warehouses in Lagos, Kano, Onicha, Abuja, and operates warehouses with a total area of more than 3000 square meters, covering import by sea and air, regional distribution to meet your different storage needs. Warehouse staff rotate 24 hours to ensure the smooth storage and transportation of each shipment, so that customers rest assured, show our professional is our goal.',
		content6: 'Delemico has a perfect local distribution network, to solve the problem of local distribution is the direction we have been working hard. To achieve local door-to-door service, to solve the "last kilometer" difficulties for e-commerce.',
		Cargotracking: 'Cargo tracking centre',
		Search: 'Search',
		trackingNumber: 'Tracking Number',
		details: 'View Details',
		China: 'China',
		Nigeria: 'Nigeria',
		Ghana: 'Ghana',
		New: 'News',
		warehousing: 'Warehousing',
		Containerloading: 'Container loading',
		Setsail: 'Set sail',
		transshipment: 'Transshipment',
		Arrivalatport: 'Arrival at port',
		Customsclearance: 'Customs clearance',
		dispatch: 'Dispatch',
		Loadedcontainer: 'Loaded container',
		Indonesia: 'Indonesia',
		contactInformation: 'Contact Information',
		contacts: 'Contacts',
		warehouseAddress: 'Warehouse Address',
		LogoTitle: 'DYNAMIC SUPPLY CHAIN'
	},
}